* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.App {
  width: 100vw;
  height: 100vh;
  
}